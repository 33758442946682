import React, { memo, useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'

import { LIKE_TYPES } from '../constants'
import { getLikes } from '../services/likes'

import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'
import SEO from '../components/SEO'
import Img from '../components/Img'
import Tags from '../components/Tags'
import SocialButtons from '../components/SocialButtons'
import Like from '../components/Like'

import NoImg from '../assets/img/no_img.svg'

const BlogPost = ({ data }) => {
  const [likes, setLikes] = useState({})
  const fetchData = async (slugs) => {
    try {
      const data = await getLikes(slugs, LIKE_TYPES.BLOG)
      setLikes(data)
    } catch (error) {
      console.warn(error)
    }
  }
  useEffect(() => {
    // Get likes data
    const slug = data &&
    data.allWpBlogPost &&
    data.allWpBlogPost.edges &&
    data.allWpBlogPost.edges[0] &&
    data.allWpBlogPost.edges[0].node &&
    data.allWpBlogPost.edges[0].node.slug
    fetchData([slug] || [])
  }, [])
  if (data &&
    data.allWpBlogPost &&
    data.allWpBlogPost.edges &&
    data.allWpBlogPost.edges[0] &&
    data.allWpBlogPost.edges[0].node
  ) {
    const post = data.allWpBlogPost.edges[0].node
    const {
      slug,
      uri,
      title,
      date,
      // modified,
      seo,
      acf_blog: {
        featured,
        mainImage,
        tags,
        excerpt,
        content,
        enableSocialButtons
      }
    } = post
    const refetch = async () => {
      try {
        const data = await getLikes([slug], LIKE_TYPES.BLOG)
        setLikes(data)
      } catch (error) {
        console.warn(error)
      }
    }
    // console.log('likes', likes)
    return (
      <PageWrapper>
        <SEO
          title={seo.title || title}
          description={excerpt}
          url={`${data.site.siteMetadata.url}${uri}`}
          type="article"
          image={mainImage ? mainImage.sourceUrl : null}
          description={seo.metaDesc}
          // meta={seo}
          date={date}
        />
        <section className="blogPost">
          <SectionTitle title="Blog" subtitle="ブログ" />
          <article>
            <div className="mainImage">
              <Img
                src={mainImage ? mainImage.sourceUrl : NoImg}
                alt={mainImage ? mainImage.altText : `${title}画像`}
                title={mainImage ? mainImage.title : ''}/>
            </div>
            <h1 className="title large">{title}</h1>
            <div className="subTitle flex justify-between">
              <span className="date">{date}</span>
              <Tags tags={tags} type="blog" />
            </div>
            <div className="social">
              <Like
                slug={slug}
                likes={(likes[slug] && likes[slug].count) || 0}
                like={(likes[slug] && likes[slug].like) || false}
                refetch={refetch}
                type={LIKE_TYPES.BLOG}
              />
              {
                enableSocialButtons ? <SocialButtons shareURL={`${data.site.siteMetadata.url}${uri}`} title={seo.title || title}/> : null
              }
            </div>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </article>
        </section>
      </PageWrapper>
    )
  } else {
    navigate('/404')
  }
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export default memo(BlogPost)

export const postQuery = graphql`
  query blogPostQuery($id: String!) {
    allWpBlogPost(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          uri
          slug
          title
          status
          date(formatString: "YYYY/MM/DD")
          modified(formatString: "YYYY/MM/DD")
          seo {
            title
            metaDesc
          }
          acf_blog {
            featured
            content
            excerpt
            enableSocialButtons
            mainImage {
              title
              altText
              sourceUrl
            }
            tags: blogTags {
              name
              slug
              termTaxonomyId
              description
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`
